@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;500;700&display=swap);
div,
p,
li,
span {
	font-weight: 500;
}

strong {
	font-weight: 700;
}

body {
	margin: 0;
	/*
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  */
	font-family: 'Quicksand', 'Helvetica-Neue', 'Arial', 'sans-serif';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body * {
	box-sizing: border-box;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}




.wrapper-class{padding:2px;border:1px solid #ccc}.editor-class{background-color:#eee;color:#444;padding:1rem;border:1px solid #ccc;min-height:24em;max-height:24em;overflow-y:auto}.editor-class div,.editor-class p,.editor-class li,.editor-class span{font-weight:inherit}.editor-class div,.editor-class p,.editor-class li,.editor-class span,.editor-class h1,.editor-class h2,.editor-class h3,.editor-class h4,.editor-class h5,.editor-class h6,.editor-class h7{line-height:1em}.editor-class b,.editor-class strong{font-size:3em;font-weight:800;color:#c3172f}.toolbar-class{border:1px solid #ccc}.toolbar-class li,.toolbar-class span{color:#000}
.account-section h1,.account-section h2,.account-section h3{margin:4px 0;padding:0}.account-section h1{text-transform:uppercase;font-size:18px;line-height:.9em;padding-bottom:4px;margin:0;margin-bottom:4px;border-bottom:4px solid #ccc}.account-section p{margin:4px 0}.account-section td p,.account-section tbody th p{margin:0;line-height:1.2em}.account-section em.detail{font-size:.85em}.account-section td,.account-section tbody th{vertical-align:top}.account-section.right{text-align:right;background-color:#f6f6f6;box-shadow:0 0 16px rgba(0,0,0,.2);padding:12px;height:100%}.account-section.right h1{text-align:right}.account-section.right p,.account-section.right h4{line-height:1.2em}
body{line-height:1em}.App-logo{height:40vmin;pointer-events:none}.App-header{min-height:8vh;display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center;padding-top:calc(10px + .5vmin);padding-bottom:calc(10px + .5vmin);font-size:calc(10px + 2vmin);margin:0 auto;border:4px solid #ddd;border-top:0;border-bottom-left-radius:5vw;border-bottom-right-radius:5vw;box-shadow:0 4px 12px rgba(0,0,0,.15);width:50%}@media(max-width: 600px){.App-header{width:95%}}.CALCULATED_PROPERTIES_EXAMPLE_RELATIVE_CHANGING_FONT_SIZE{background-color:#282c34;min-height:100vh;display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center;font-size:calc(10px + 2vmin);color:#fff}.App-link{color:#61dafb}@-webkit-keyframes App-logo-spin{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes App-logo-spin{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}
